// 用户当面付，充值
<template>
    <div class="top-up-out-box">
        <div class="top-up-out">
            <div class="top-up-title">
                <icon-font type="icon-zhifubao" class="icon-img"/>支付宝扫码支付
            </div>
            <el-form label-position="top" label-width="100px" :model="upform" :rules="rules" ref="upform">
                <div class="topUp-tag-out">
                    <div class="topUp-tag" v-for="(item,i) in tagList" :key="i" :class="selectTag == item.id?'isSelect':'unSelect'" @click="selectThis(item)"> 
                        <span>{{ item.title }}</span>
                    </div>
                </div>
                <el-dialog
                width="30%"
                title="请使用支付宝扫码支付"
                v-model="innerVisible"
                :destroy-on-close = "true"
                append-to-body
                @close='closeDialog'>
                <div class="topUp-code-box">
                    <setQrcode :text="link" :src="imgSrc" :width="200" :height="200" :size="90"></setQrcode>
                </div>
                <div class="topUp-code-title">￥{{ upform.amount }}</div>
                </el-dialog>
                <div v-if="sohwInput == true">
                    <el-form-item label="自定义金额(元)" prop="amount">
                        <el-input v-model="upform.amount"  placeholder="请输入金额" oninput="value=value.replace(/[^0-9.]/g,'')">
                            <template #append>￥</template>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="footer">
                    <el-form-item>
                        <el-button class="cancel" plain @click="resetUpForm('upform')" round>取消</el-button>
                        <el-button class="confirmAdd" @click="submitUpForm('upform')" round>充值</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
import pay from '@/api/web/pay'
import setQrcode from '@/components/setQrcode.vue'; 
import emitter from '@/utils/bus.js';
import { IconFont } from "@/utils/iconfont"
export default {
  components: {
      IconFont,
      setQrcode
  },
  data() {
    const validatePrice = (rule,value,callback) =>{
        let reg = /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/
        if(!value){
            callback(new Error('单价不能为空'))
        }else if(!reg.test(value)){
            callback(new Error('请输入正确的金额格式'))
        }else if(value.length > 10){
            callback(new Error('最多可输入10个字符'))
        }else{
        callback();
        }
    };
    return {
        innerVisible:false,
        centerDialogVisible:false,
        tagList:[
            {
                id:1,
                title:'30元',
                value:30.00
            },
            {
                id:2,
                title:'50元',
                value:50.00
            },
            {
                id:3,
                title:'100元',
                value:100.00
            },
            {
                id:4,
                title:'200元',
                value:200.00
            },
            {
                id:5,
                title:'300元',
                value:300.00
            },
            {
                id:6,
                title:'自定义',
                value:''
            }
        ],
        selectTag:1,
        sohwInput:false,
        link:'',
        imgSrc:'',
        upform: {
            amount:'30'
        },
        rules: {
            amount: [{trigger: 'blur', validator:validatePrice}]
        }
    };
  },
  mounted() {
        emitter.on('getTarget', (target) => {  
            if(target == 'ok'){
                this.imgSrc = 'ok'
            }
            // this.innerVisible = false
            // this.closeDialog() 
        });
    },
  methods: {
    // topUp(){
    //     this.centerDialogVisible = true
    // },
    selectThis(item){
        this.selectTag = item.id
        if(item.id == 6){
            this.upform.amount = ''
            this.sohwInput = true
        }else{
            this.sohwInput = false
            this.upform.amount = item.value
        }
    },
    // 表单提交
    submitUpForm(formName) {
        this.$refs[formName].validate((valid) => {
            if (valid) {
                pay.faceToAlipay({
                    total_amount: this.upform.amount
                }).then((res) => {
                    this.link = res.data
                    this.innerVisible = true
                })
            } else {
                // console.log('error submit!!');
                return false;
            }
        });
    },
    resetUpForm(formName) {
        this.closeDialog()
        this.$refs[formName].resetFields();
        this.centerDialogVisible = false
    },
    // 关闭弹窗清除二维码
    closeDialog(){
        let codeHtml = document.getElementById("qrCode")
        codeHtml.innerHTML = ""
    },
  },
  created() {
    
  },
};
</script>
<style lang="scss" scoped>
.top-up-out-box{
    height: calc(100vh - 135px);
    width: 100%;
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.top-up-title{
    height: 5rem;
    line-height: 5rem;
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.icon-img{
    font-size: 1.875rem;
}
.top-up-out{
    width: 40%;
}
.confirmAdd{
    background: #637DFF;
    color: #fff;
}
.top-up{
    margin-left: 1.875rem;
}
.topUp-tag-out{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.topUp-tag{
    width: 6.25rem;
    height: 5rem;
    margin-bottom: .625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: .375rem;
    cursor: pointer;
}
.isSelect{
    background: #409eff;
}
.unSelect{
    background: #b3d7fd;
}
.topUp-code-title{
    width: 100%;
    text-align: center;
    padding: .3rem 0;
    font-size: 1.75rem;
    font-weight: 600;
}
.topUp-code-box{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3.125rem 0;
}
.footer{
    display: flex;
    justify-content: flex-end;
}
.ok-box{
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: #fff;
    border-radius: 50%;
}
.img-ok-err{
    font-size: 80px;
}
</style>